.hamburger-menu {
    position: fixed;
    top: 5vh;
    right: 5vw;
    z-index: 3;
    display: inline-block;
    margin: 35px 25px;
    transition: all 0.3s ease-in-out;
  
    &:hover {
      cursor: pointer;

      .burger-item:nth-child(2){
        width: 30px;
      }
    }
  
    .burger-item {
      background: $colorLightGreen;
      display: block;
      height: 3px;
      margin: 0 0 10px;
      transition: all 0.3s ease-in-out;
      width: 40px;
    }
      
    &.open {
  
      .burger-item {
        margin: 0 0 5px;
  
        &:first-child {
          transform: rotate(45deg);
          transform-origin: 10px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          transform-origin: 8px;
        }
      }
    }
  }


  .menuContainer {
    width: 100%;
    position: fixed;
    background-color: white;
    transition: left 1s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 100vw;
    z-index: 2;
    -webkit-transition: left 0.5s ease-out;
    -moz-transition: left 1s ease-in;
    -ms-transition: left 1s ease-in;
    -o-transition: left 1s ease-in;

    ul {

      li {
        text-align: center;
        font-size: 4vw;
        text-transform: uppercase;
        font-family: Butler_Light;
        cursor: pointer;
        margin-bottom: 2vh;
      }
    }

    .media {
      display: flex;
      flex-direction: row;
      position: absolute;
      bottom: 8vh;

      p {
        margin: 0 1.5vw;
      }
    }
}


@media screen and (max-width:749px) {
  .menuContainer {
    ul {
      li {
        font-size: 7vw;
      }
    }

    .media {
      flex-direction: column;
      bottom: 8vh;
      text-align: center;

      p {
       text-align: center;
       line-height: 7px;
      }
    }
}
}
